<template>
  <template v-if="selectedCustomPage">
    <section
      v-if="selectedCustomPage.Type === 'page'"
      class="v-custom-page"
      v-html="selectedCustomPage.Text"
    />
    <section
      v-if="
        (selectedCustomPage.Type === 'gallery' ||
          selectedCustomPage.Type === 'pageWithImages') &&
        (selectedCustomPage.ShowFooter || selectedCustomPage.ShowHeader)
      "
      class="v-custom-page--gallery"
    >
      <div class="v-row">
        <div
          v-if="!stringIsNullOrWhitespace(selectedCustomPage.Title)"
          class="v-col-12"
        >
          <h1 v-html="selectedCustomPage.Title" />
        </div>
        <div
          v-if="!stringIsNullOrWhitespace(selectedCustomPage.Description)"
          class="v-col-12 v-mb-lg"
        >
          <span v-html="selectedCustomPage.Description" />
        </div>
        <div class="v-col-12">
          <div
            :class="[
              selectedCustomPage.Type === 'pageWithImages'
                ? 'v-container'
                : 'v-w-100'
            ]"
          >
            <common-gallery
              :auto-play-delay="0"
              :images="selectedCustomPage.Images"
              :items-number="
                selectedCustomPage.Type === 'pageWithImages' &&
                selectedCustomPage.CustomNumber > 0
                  ? selectedCustomPage.CustomNumber
                  : 2
              "
              :show-thumbs="false"
            />
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="
        selectedCustomPage.Type === 'gallery' ||
        selectedCustomPage.Type === 'pageWithImages'
      "
      class="v-custom-page--gallery__full-page"
    >
      <h1
        v-if="!stringIsNullOrWhitespace(selectedCustomPage.Title)"
        class="v-custom-page-title-fullscreen"
        v-html="selectedCustomPage.Title"
      />

      <div
        v-if="!stringIsNullOrWhitespace(selectedCustomPage.Description)"
        class="v-custom-page-title-fullscreen v-mb-lg"
        v-html="selectedCustomPage.Description"
      />
      <div class="v-w-100 v-custom-page-gallery-fullscreen">
        <common-gallery
          :auto-play-delay="0"
          :images="selectedCustomPage.Images"
          :items-number="
            selectedCustomPage.Type === 'pageWithImages' &&
            selectedCustomPage.CustomNumber > 0
              ? selectedCustomPage.CustomNumber
              : 2
          "
          :show-thumbs="false"
        />
      </div>
    </section>
  </template>
</template>

<script setup lang="ts">
import type { CustomPage } from '~types/settingsVariants'

import { type GUID, useCommon } from '@arora/common'

const { id } = defineProps<{ id: GUID }>()

const { stringIsNullOrWhitespace } = useCommon()

const appConfig = useAppConfig()
const selectedCustomPage = computed<CustomPage | undefined>(() => {
  return (appConfig.VueSettingsPreRun.CustomPages ?? []).find(
    (page) => page.ID === id
  )
})
</script>

<style lang="scss">
.v-custom-page-title-fullscreen {
  color: #eeeeee;
  height: 2rem;
  width: 100%;
}
.v-custom-page-gallery-fullscreen {
  height: calc(100vh - 4rem);
  position: relative;

  .v-gallery {
    height: 100%;
  }
}

.v-custom-page {
  &--gallery__full-page {
    padding: 1.25rem;
    height: 100vh;
  }
}
</style>
